import React, { useEffect, useState, useCallback } from "react"
import { subDays, addDays, formatISO } from "date-fns"
// import { subDays, addDays, formatISO, differenceInCalendarDays } from "date-fns"

import Form from "./form"
import TopBar from "./topBar"
import Picker from "./picker"
import FabButton from "./fabButton"
import Loader from "./loader"
import Snackmessage from "./snackmessage"

const useSubmit = submitFunction => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = async () => {
    try {
      setLoading(true)
      setError(null)
      await submitFunction()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  return [handleSubmit, loading, error]
}

export default function Calendar({ firebase, entries, loadEntries }) {
  const db = firebase.firestore()

  const START_DATE = new Date(2020, 5, 17)
  const dateFormat = "EEE dd MMM, yyyy"
  const today = new Date()
  const yesterday = subDays(today, 1)
  const tomorrowOfStartDay = addDays(START_DATE, 1)

  const getEntryDefaults = useCallback(
    (dt = new Date()) => ({
      taken: false,
      time: firebase.firestore.Timestamp.now(),
      description: "trefero 5mg",
      // description:
      //   differenceInCalendarDays(START_DATE, dt) % 2
      //     ? "trefero 5mg"
      //     : "trefero 10mg",
    }),
    [firebase.firestore.Timestamp]
  )

  const toFirestoreTS = dt => firebase.firestore.Timestamp.fromDate(dt)
  const getFirestoreID = dt => formatISO(dt, { representation: "date" })

  async function saveEntry() {
    const { time, taken, description } = selectedDayEntry
    const id = selectedDayEntry.id || getFirestoreID(selectedDay)

    try {
      await db.collection("pill-calendar").doc(id).set({
        taken,
        time,
        description,
      })

      setShowSuccess(true)
      setShowError(false)
    } catch (err) {
      setShowSuccess(false)
      setShowError(true)
    }
  }

  const [selectedDay, setSelectedDay] = useState(today)
  // const [editMode, setEditMode] = useState(true);
  const [selectedDayEntry, setSelectedDayEntry] = useState(getEntryDefaults())
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  // const [handleSubmit, loading, error] = useSubmit(saveEntry);
  const [handleSubmit, loading] = useSubmit(saveEntry)

  useEffect(() => {
    const id = getFirestoreID(selectedDay)
    const data = entries[id] || getEntryDefaults(selectedDay)
    setSelectedDayEntry(data)
  }, [selectedDay, entries, getEntryDefaults])

  return (
    <>
      <TopBar
        {...{
          firebase,
          selectedDay,
          setSelectedDay,
          dateFormat,
          tomorrowOfStartDay,
          yesterday,
        }}
      />

      <Form
        {...{
          selectedDay,
          selectedDayEntry,
          setSelectedDayEntry,
          toFirestoreTS,
          handleSubmit,
        }}
      />

      <FabButton {...{ setCalendarOpen }} />

      <Picker
        {...{
          selectedDay,
          setSelectedDay,
          today,
          getFirestoreID,
          entries,
          calendarOpen,
          setCalendarOpen,
          dateFormat,
          minDate: START_DATE,
          loadEntries,
        }}
      />

      <Loader open={loading} />

      <Snackmessage
        open={showSuccess}
        message="Успешно зачуван запис!"
        onClose={() => {
          setShowSuccess(false)
        }}
      />
      <Snackmessage
        open={showError}
        message="Грешка при зачувувањето!"
        onClose={() => {
          setShowError(false)
        }}
      />
    </>
  )
}
