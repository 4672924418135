import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import format from "date-fns/format";
import parse from "date-fns/parse";

import useStyles from "../styles";

export default function Form({
  selectedDay,
  selectedDayEntry,
  setSelectedDayEntry,
  toFirestoreTS,
  handleSubmit
}) {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.content} maxWidth="xs">
      <div className={classes.appBarSpacer} />

      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit()
        }}
      >
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                type="checkbox"
                checked={selectedDayEntry && selectedDayEntry.taken}
                onChange={(e) => {
                  setSelectedDayEntry({
                    ...selectedDayEntry,
                    taken: e.target.checked,
                  });
                }}
              />
            }
            label="Апчето е испиено"
          />
        </FormGroup>

        <TextField
          label="Време"
          type="time"
          value={
            selectedDayEntry.time &&
            format(selectedDayEntry.time.toDate(), "HH:mm")
          }
          fullWidth
          variant="outlined"
          margin="normal"
          disabled={selectedDayEntry && !selectedDayEntry.taken}
          onChange={(e) => {
            setSelectedDayEntry({
              ...selectedDayEntry,
              time: toFirestoreTS(parse(e.target.value, "HH:mm", selectedDay)),
            });
          }}
        />

        <FormControl
          className={classes.formControl}
          margin="normal"
          variant="outlined"
          fullWidth
          disabled={selectedDayEntry && !selectedDayEntry.taken}
        >
          <InputLabel htmlFor="description">Апче</InputLabel>
          <Select
            native
            required
            value={selectedDayEntry && selectedDayEntry.description}
            label="Апче"
            onChange={(e) => {
              setSelectedDayEntry({
                ...selectedDayEntry,
                description: e.target.value,
              });
            }}
          >
            <option value="trefero 5mg">trefero 5mg</option>
            <option value="trefero 10mg">trefero 10mg</option>
            <option value="trefero 15mg">trefero 15mg</option>
          </Select>
        </FormControl>

        <FormGroup row>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
          >
            Зачувај
          </Button>
        </FormGroup>
      </form>
    </Container>
  );
}
