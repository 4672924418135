import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "../styles";

export default function Loader({ open }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.loginBackdrop} open={open}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
