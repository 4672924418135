import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import useStyles from "./styles"

export default function AuthLoader() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Backdrop className={classes.loginBackdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
