import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import useStyles from "./styles";

import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

export default function Login({ firebase, uiConfig }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Container component="main" className={classes.content} maxWidth="xs">
        <div className={classes.appBarSpacer} />

          <div className={classes.signInBox}>
            <Avatar className={classes.signInAvatarIcon}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Најави се
            </Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </div>
        </Container>
    </div>
  );
}
