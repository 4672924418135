import React from "react"
import Container from "@material-ui/core/Container"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"

import { format, isAfter } from "date-fns"
import mkLocale from "date-fns/locale/mk"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import useStyles from "../styles"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Picker({
  selectedDay,
  setSelectedDay,
  getFirestoreID,
  entries,
  calendarOpen,
  setCalendarOpen,
  dateFormat,
  minDate,
  today,
  loadEntries,
}) {
  const classes = useStyles()

  const renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    const id = getFirestoreID(date)
    const entry = entries[id]

    if (
      !dayInCurrentMonth ||
      isAfter(date, today) ||
      isAfter(minDate, date)
    ) {
      return dayComponent
    } else {
      const dotClass = entry && entry.taken
        ? classes.dayWithDotSuccess
        : classes.dayWithDotWarning

      return (
        <div className={classes.dayWithDotContainer}>
          {dayComponent}
          <div className={dotClass} />
        </div>
      )
    }
  }

  return (
    <Dialog open={calendarOpen} fullScreen TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {format(selectedDay, dateFormat, { locale: mkLocale })}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setCalendarOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />

      <Container className={classes.datePicker} maxWidth="xs">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={mkLocale}>
          <DatePicker
            margin="normal"
            renderDay={renderDayInPicker}
            disableToolbar={true}
            variant="static"
            format={dateFormat}
            value={selectedDay}
            maxDate={today}
            minDate={minDate}
            onChange={date => {
              setSelectedDay(date)
              setCalendarOpen(false)
            }}
            onMonthChange={date => {
              return loadEntries(date)
            }}
          />
        </MuiPickersUtilsProvider>
      </Container>
    </Dialog>
  )
}
