import React, { useEffect, useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { startOfMonth, lastDayOfMonth } from "date-fns"
import useStyles from "../styles"
import Calendar from "./calendar"

export default function PillCalendar({ firebase }) {
  const db = firebase.firestore()
  const classes = useStyles()

  const [entries, setEntries] = useState()
  const [entriesLoaded, setEntriesLoaded] = useState(false)

  const loadEntries = async (dt = new Date()) => {
    const toDate = firebase.firestore.Timestamp.fromDate(lastDayOfMonth(dt))
    const fromDate = firebase.firestore.Timestamp.fromDate(startOfMonth(dt))

    const data = await db
      .collection("pill-calendar")
      .where("time", ">=", fromDate)
      .where("time", "<=", toDate)
      .get()
      .then(querySnapshot => {
        const res = {}
        querySnapshot.forEach(doc => {
          res[doc.id] = doc.data()
        })

        return res
      })

    setEntries({
      ...entries,
      ...data,
    })
    setEntriesLoaded(true)
  }

  // load entries for current month
  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db])

  return (
    <div className={classes.root}>
      <CssBaseline />

      {!entriesLoaded ? (
        <Backdrop className={classes.loginBackdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Calendar {...{ firebase, entries, loadEntries }} />
      )}
    </div>
  )
}
