import React from "react"
import { Helmet } from "react-helmet"
import PillCalendar from "../components/pill-calendar"

const PillCalendarPage = () => (
  <>
    <Helmet>
      <title>Pill Calendar</title>
      <meta name="robots" content="noindex" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
    <PillCalendar />
  </>
)

export default PillCalendarPage
