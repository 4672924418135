import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import SkipNextTwoToneIcon from "@material-ui/icons/SkipNextTwoTone"
import SkipPreviousTwoToneIcon from "@material-ui/icons/SkipPreviousTwoTone"
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone"

import { format, subDays, addDays, isAfter } from "date-fns"
import mkLocale from "date-fns/locale/mk"

import useStyles from "../styles"

export default function TopBar({
  firebase,
  selectedDay,
  setSelectedDay,
  dateFormat,
  tomorrowOfStartDay,
  yesterday,
}) {
  const classes = useStyles()

  return (
    <AppBar position="absolute">
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <Tooltip
            title={format(subDays(selectedDay, 1), dateFormat, {
              locale: mkLocale,
            })}
          >
            <span>
              <IconButton
                color="inherit"
                disabled={isAfter(tomorrowOfStartDay, selectedDay)}
                onClick={() => {
                  setSelectedDay(subDays(selectedDay, 1))
                }}
              >
                <SkipPreviousTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>

          {format(selectedDay, dateFormat, { locale: mkLocale })}

          <Tooltip
            title={format(addDays(selectedDay, 1), dateFormat, {
              locale: mkLocale,
            })}
          >
            <span>
              <IconButton
                color="inherit"
                disabled={isAfter(selectedDay, yesterday)}
                onClick={() => {
                  setSelectedDay(addDays(selectedDay, 1))
                }}
              >
                <SkipNextTwoToneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Typography>

        <Tooltip title="Одјави се">
          <IconButton color="inherit" onClick={() => firebase.auth().signOut()}>
            <ExitToAppTwoToneIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}
