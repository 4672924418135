import React from "react";
import Fab from "@material-ui/core/Fab";
import CalendarTodayTwoToneIcon from "@material-ui/icons/CalendarTodayTwoTone";

import useStyles from "../styles";

export default function FabButton({ setCalendarOpen }) {
  const classes = useStyles();

  return (
    <Fab
      className={classes.calendarFab}
      color="default"
      style={{ position: "fixed" }}
      onClick={() => setCalendarOpen(true)}
    >
      <CalendarTodayTwoToneIcon />
    </Fab>
  );
}
