import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import AuthLoader from "./AuthLoader";
import Login from "./Login";
import Calendar from "./Calendar";

const firebaseConfig = {
  apiKey: "AIzaSyD7Kf7NeEJPVIFAvLGU7lfb9GoIrmpLpv0",
  authDomain: "stojkov-website.firebaseapp.com",
  databaseURL: "https://stojkov-website.firebaseio.com",
  projectId: "stojkov-website",
  storageBucket: "stojkov-website.appspot.com",
  messagingSenderId: "61036666270",
  appId: "1:61036666270:web:aa86efe45b52ea15190a8f",
}

function App() {
  const [user, setUser] = useState(null);
  const [authReady, setAuthReady] = useState(false);
  const [uiConfig, setUiConfig] = useState(null)

  useEffect(() => {
    const authCheck = async () => {
      if (typeof window !== "undefined") {
        firebase.initializeApp(firebaseConfig)
        setUiConfig({
          signInFlow: "redirect",
          signInSuccessUrl: "/pill-calendar",
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
        })
        setUser(firebase.auth().currentUser)
        firebase.auth().onAuthStateChanged((user) => {
          setUser(user);
          setAuthReady(true);
        });
      }
    }

    authCheck()
  }, []);

  return !authReady ? (
    <AuthLoader />
  ) : !user ? (
    <Login {...{ firebase, uiConfig }} />
  ) : (
    <Calendar {...{ firebase, user }} />
  );
}

export default App;
