import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export default function Snackmessage({ open, message, onClose }) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      onClose()
    };

  //   return (
  //     <Snackbar open={open} autoHideDuration={6000}>
  //       <Alert severity={severity}>
  //         {message}
  //       </Alert>
  //     </Snackbar>
  //   );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      message={message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
